define('ember-cli-autocomplete-input/components/autocomplete-input', ['exports', 'ember-cli-autocomplete-input/templates/components/autocomplete-input', 'ember-cli-keyboard-nav/mixins/keyboard-nav'], function (exports, _emberCliAutocompleteInputTemplatesComponentsAutocompleteInput, _emberCliKeyboardNavMixinsKeyboardNav) {
  'use strict';

  exports['default'] = Ember.Component.extend(_emberCliKeyboardNavMixinsKeyboardNav['default'], {

    layout: _emberCliAutocompleteInputTemplatesComponentsAutocompleteInput['default'],

    // Hooks

    init: function init() {
      this._super.apply(this, arguments);

      if (!this.get('results')) {
        this.set('results', []);
      }
    },
    didInsertElement: function didInsertElement() {
      this.bindKeys(this.$('input[type="text"]'));
      this.set('lastTerm', this.get('term'));
    },

    // Attributes

    name: '',

    resultName: 'name',

    resultValue: 'value',

    highlightedResultIndex: -1,

    term: '',

    lastTerm: '',

    placeholder: '',

    autocomplete: '',

    // Properties

    highlightedResult: Ember.computed('results.[]', 'highlightedResultIndex', function () {
      return this.get('results')[this.get('highlightedResultIndex')];
    }),

    hasResults: Ember.computed.notEmpty("results"),

    // Observers

    termDidChange: Ember.observer('term', function () {
      this.send('updateTerm', this.get('term'));
    }),

    // Keyboard Nav actions

    onEnterPress: function onEnterPress() {
      var result = this.get("results")[this.get("highlightedResultIndex")];

      if (result) {
        this.send("selectResult", result);
      }
    },
    onEscPress: function onEscPress() {
      this.send("clearSearch");
    },
    onDownPress: function onDownPress() {
      var index = 0;

      if (this.get("highlightedResultIndex") >= 0) {
        index = this.get("highlightedResultIndex") + 1;
      }

      if (index > this.get("results").length - 1) {
        index = 0;
      }

      this.set("highlightedResultIndex", index);
    },
    onUpPress: function onUpPress() {
      var lastItem = this.get("results").length - 1;
      var index = lastItem;

      if (this.get("highlightedResultIndex") >= 0) {
        index = this.get("highlightedResultIndex") - 1;
      }

      if (index < 0) {
        index = lastItem;
      }

      this.set("highlightedResultIndex", index);
    },

    actions: {
      selectResult: function selectResult(result) {
        this.get('selectResult')(result);
      },
      updateTerm: function updateTerm(term) {
        if (term !== this.get('lastTerm')) {
          this.set('lastTerm', term);
          this.get('updateTerm')(term);
        }
      },
      clearSearch: function clearSearch() {
        this.get("clearSearch")();
      }
    }

  });
});