define('ember-pikaday/mixins/pikaday', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  /* globals Pikaday */
  'use strict';

  var isPresent = _ember['default'].isPresent;

  exports['default'] = _ember['default'].Mixin.create({
    _options: _ember['default'].computed('options', 'i18n', {
      get: function get() {
        var options = this._defaultOptions();

        if (isPresent(this.get('i18n'))) {
          options.i18n = this.get('i18n');
        }
        if (isPresent(this.get('position'))) {
          options.position = this.get('position');
        }
        if (isPresent(this.get('reposition'))) {
          options.reposition = this.get('reposition');
        }

        _ember['default'].merge(options, this.get('options') || {});
        return options;
      }
    }),

    _defaultOptions: function _defaultOptions() {
      var firstDay = this.get('firstDay');

      return {
        field: this.get('field'),
        container: this.get('pikadayContainer'),
        bound: this.get('pikadayContainer') ? false : true,
        onOpen: _ember['default'].run.bind(this, this.onPikadayOpen),
        onClose: _ember['default'].run.bind(this, this.onPikadayClose),
        onSelect: _ember['default'].run.bind(this, this.onPikadaySelect),
        onDraw: _ember['default'].run.bind(this, this.onPikadayRedraw),
        firstDay: typeof firstDay !== 'undefined' ? parseInt(firstDay, 10) : 1,
        format: this.get('format') || 'DD.MM.YYYY',
        yearRange: this.determineYearRange(),
        minDate: this.get('minDate') || null,
        maxDate: this.get('maxDate') || null,
        theme: this.get('theme') || null
      };
    },

    didUpdateAttrs: function didUpdateAttrs(_ref) {
      var newAttrs = _ref.newAttrs;

      this._super.apply(this, arguments);
      this.setPikadayDate();
      this.setMinDate();
      this.setMaxDate();

      if (newAttrs.options) {
        this._updateOptions();
      }
    },

    didRender: function didRender() {
      this._super.apply(this, arguments);
      this.autoHideOnDisabled();
    },

    setupPikaday: function setupPikaday() {
      var pikaday = new Pikaday(this.get('_options'));

      this.set('pikaday', pikaday);
      this.setPikadayDate();
    },

    willDestroyElement: function willDestroyElement() {
      this.get('pikaday').destroy();
    },

    setPikadayDate: function setPikadayDate() {
      var format = 'YYYY-MM-DD';
      var value = this.get('value');

      if (!value) {
        this.get('pikaday').setDate(value, true);
      } else {
        var date = this.get('useUTC') ? (0, _moment['default'])(_moment['default'].utc(value).format(format), format).toDate() : value;

        this.get('pikaday').setDate(date, true);
      }
    },

    setMinDate: function setMinDate() {
      if (this.get('minDate')) {
        this.get('pikaday').setMinDate(this.get('minDate'));
      }
    },

    setMaxDate: function setMaxDate() {
      if (this.get('maxDate')) {
        this.get('pikaday').setMaxDate(this.get('maxDate'));
      }
    },

    onOpen: _ember['default'].K,
    onClose: _ember['default'].K,
    onSelection: _ember['default'].K,
    onDraw: _ember['default'].K,

    onPikadaySelect: function onPikadaySelect() {
      this.userSelectedDate();
    },

    onPikadayRedraw: function onPikadayRedraw() {
      this.get('onDraw')();
    },

    userSelectedDate: function userSelectedDate() {
      var selectedDate = this.get('pikaday').getDate();

      if (this.get('useUTC')) {
        selectedDate = _moment['default'].utc([selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate()]).toDate();
      }

      this.get('onSelection')(selectedDate);
    },

    determineYearRange: function determineYearRange() {
      var yearRange = this.get('yearRange');

      if (yearRange) {
        if (yearRange.indexOf(',') > -1) {
          var yearArray = yearRange.split(',');

          if (yearArray[1] === 'currentYear') {
            yearArray[1] = new Date().getFullYear();
          }

          return yearArray;
        } else {
          return yearRange;
        }
      } else {
        return 10;
      }
    },

    autoHideOnDisabled: function autoHideOnDisabled() {
      if (this.get('disabled') && this.get('pikaday')) {
        this.get('pikaday').hide();
      }
    },

    _updateOptions: function _updateOptions() {
      this.get('pikaday').config(this.get('_options'));
    }
  });
});