define('ember-select-guru/components/ember-select-dropdown', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var computed = _ember['default'].computed;
  var run = _ember['default'].run;

  exports['default'] = _ember['default'].Component.extend({
    clickOutEventNamespace: 'el-popup',
    classNames: 'ember-select-guru__dropdown',

    didInsertElement: function didInsertElement() {
      _ember['default'].$(document).bind(this.get('_clickOutEventName'), { component: this }, run.bind(this, this.clickoutHandler));
    },

    willDestroyElement: function willDestroyElement() {
      _ember['default'].$(document).unbind(this.get('_clickOutEventName'));
    },

    _willHideDropdown: function _willHideDropdown() {
      this.sendAction('willHideDropdown');
    },

    clickoutHandler: function clickoutHandler(event) {
      var _this = this;

      if (this.$().parent().children('.ember-select-guru__trigger').has(event.target).length > 0 || this.$().parent().children('.ember-select-guru__trigger')[0] === event.target || this.$('input.ember-select-guru__search')[0] === event.target) {
        return;
      } else {
        run(function () {
          _this._willHideDropdown();
        });
      }
    },

    _clickOutEventName: computed('clickOutEventNamespace', function () {
      return '\n      click.' + this.get('clickOutEventNamespace') + '.' + this.get('elementId') + '\n    ';
    })
  });
});