define('ember-composable-helpers/utils/get-index', ['exports', 'ember-composable-helpers/utils/is-equal'], function (exports, _emberComposableHelpersUtilsIsEqual) {
  'use strict';

  exports['default'] = getIndex;

  function getIndex(array, currentValue, useDeepEqual) {
    var index = undefined;
    if (useDeepEqual) {
      index = array.findIndex(function (object) {
        return (0, _emberComposableHelpersUtilsIsEqual['default'])(object, currentValue, useDeepEqual);
      });
    } else {
      index = array.indexOf(currentValue);
    }

    return index >= 0 ? index : null;
  }
});