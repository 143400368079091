define("ember-tag-input/templates/components/tag-input", ["exports"], function (exports) {
  "use strict";

  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.7.3",
            "loc": {
              "source": null,
              "start": {
                "line": 4,
                "column": 4
              },
              "end": {
                "line": 6,
                "column": 4
              }
            },
            "moduleName": "modules/ember-tag-input/templates/components/tag-input.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1, "class", "emberTagInput-remove");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element0);
            return morphs;
          },
          statements: [["element", "action", ["removeTag", ["get", "index", ["loc", [null, [5, 59], [5, 64]]], 0, 0, 0, 0]], [], ["loc", [null, [5, 38], [5, 66]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.7.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 8,
              "column": 0
            }
          },
          "moduleName": "modules/ember-tag-input/templates/components/tag-input.hbs"
        },
        isEmpty: false,
        arity: 2,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("li");
          dom.setAttribute(el1, "class", "emberTagInput-tag");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [0]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element1, 1, 1);
          morphs[1] = dom.createMorphAt(element1, 3, 3);
          return morphs;
        },
        statements: [["inline", "yield", [["get", "tag", ["loc", [null, [3, 12], [3, 15]]], 0, 0, 0, 0]], [], ["loc", [null, [3, 4], [3, 17]]], 0, 0], ["block", "if", [["get", "_isRemoveButtonVisible", ["loc", [null, [4, 10], [4, 32]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [4, 4], [6, 11]]]]],
        locals: ["tag", "index"],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.7.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 18,
            "column": 0
          }
        },
        "moduleName": "modules/ember-tag-input/templates/components/tag-input.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("li");
        dom.setAttribute(el1, "class", "emberTagInput-new");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["block", "each", [["get", "tags", ["loc", [null, [1, 8], [1, 12]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 0], [8, 11]]]], ["inline", "input", [], ["disabled", ["subexpr", "@mut", [["get", "readOnly", ["loc", [null, [12, 13], [12, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "class", ["subexpr", "concat", ["emberTagInput-input js-ember-tag-input-new", ["subexpr", "if", [["get", "readOnly", ["loc", [null, [13, 67], [13, 75]]], 0, 0, 0, 0], " is-disabled"], [], ["loc", [null, [13, 63], [13, 91]]], 0, 0]], [], ["loc", [null, [13, 10], [13, 92]]], 0, 0], "maxlength", ["subexpr", "@mut", [["get", "maxlength", ["loc", [null, [14, 14], [14, 23]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", ["subexpr", "@mut", [["get", "placeholder", ["loc", [null, [15, 16], [15, 27]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [11, 2], [16, 4]]], 0, 0]],
      locals: [],
      templates: [child0]
    };
  })());
});