define('ember-cli-autocomplete-input/components/autocomplete-result-item', ['exports', 'ember-cli-autocomplete-input/templates/components/autocomplete-result-item'], function (exports, _emberCliAutocompleteInputTemplatesComponentsAutocompleteResultItem) {
  'use strict';

  exports['default'] = Ember.Component.extend({

    layout: _emberCliAutocompleteInputTemplatesComponentsAutocompleteResultItem['default'],

    // Attributes

    resultName: '',

    resultValue: '',

    // Methods

    getValue: function getValue(object, attrName) {
      if (object.get) {
        return object.get(attrName);
      } else {
        return object[attrName];
      }
    },

    // Properties

    name: Ember.computed('result', 'resultName', function () {
      return this.getValue(this.get('result'), this.get('resultName'));
    }),

    value: Ember.computed('result', 'resultValue', function () {
      return this.getValue(this.get('result'), this.get('resultValue'));
    }),

    highlightedValue: Ember.computed('highlightedResult', 'resultValue', function () {
      var value = '';

      if (this.get('highlightedResult')) {
        value = this.getValue(this.get('highlightedResult'), this.get('resultValue'));
      }

      return value;
    }),

    isHighlighted: Ember.computed('value', 'highlightedValue', function () {
      return this.get('value') === this.get('highlightedValue');
    }),

    actions: {
      selectResult: function selectResult(value) {
        this.get('selectResult')(value);
      }
    }

  });
});