define('ember-leaflet-marker-cluster/components/marker-cluster-layer', ['exports', 'ember', 'ember-leaflet/components/base-layer', 'ember-leaflet/mixins/container'], function (exports, _ember, _emberLeafletComponentsBaseLayer, _emberLeafletMixinsContainer) {
	'use strict';

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];return arr2;
		} else {
			return Array.from(arr);
		}
	}

	var get = _ember['default'].get;

	exports['default'] = _emberLeafletComponentsBaseLayer['default'].extend(_emberLeafletMixinsContainer['default'], {

		leafletOptions: ['showCoverageOnHover', 'zoomToBoundsOnClick', 'spiderfyOnMaxZoom', 'removeOutsideVisibleBounds', 'animate', 'animateAddingMarkers', 'disableClusteringAtZoom', 'maxClusterRadius', 'polygonOptions', 'singleMarkerMode', 'spiderLegPolylineOptions', 'spiderfyDistanceMultiplier', 'iconCreateFunction'],

		leafletEvents: [
		// Marker clutster events
		'clusterclick', 'clusterdblclick', 'clustermousedown', 'clustermouseover', 'clustermouseout', 'clustercontextmenu', 'clusteradd', 'clusterremove', 'animationend', 'spiderfied', 'unspiderfied',
		// Marker events
		'click', 'dblclick', 'mousedown', 'mouseover', 'mouseout', 'contextmenu', 'dragstart', 'drag', 'dragend', 'move', 'remove', 'add', 'popupopen', 'popupclose'],

		createLayer: function createLayer() {
			var _L;

			return (_L = this.L).markerClusterGroup.apply(_L, _toConsumableArray(get(this, 'requiredOptions')).concat([get(this, 'options')]));
		},
		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);
			this.layerSetup();
			get(this, '_childLayers').invoke('layerSetup');
		},
		willDestroyLayer: function willDestroyLayer() {
			get(this, '_childLayers').invoke('layerTeardown');
			get(this, '_childLayers').clear();
		},
		layerSetup: function layerSetup() {
			if (_ember['default'].isNone(get(this, '_layer'))) {
				this._layer = this.createLayer();
				this._addObservers();
				this._addEventListeners();
				this.didCreateLayer();
			}
			if (get(this, 'containerLayer')) {
				if (!_ember['default'].isNone(get(this, 'containerLayer')._layer)) {
					get(this, 'containerLayer')._layer.addLayer(this._layer);
				}
			}
		}
	});
});