define("ember-cli-keyboard-nav/mixins/keyboard-nav", ["exports"], function (exports) {
  "use strict";

  exports["default"] = Ember.Mixin.create({
    bindKeys: function bindKeys($object) {
      var _this = this;

      $object.keyup(function (e) {
        if (e.which === 13) {
          _this.onEnterPress();
        } else if (e.which === 27) {
          _this.onEscPress();
        } else if (e.which === 40) {
          _this.onDownPress();
        } else if (e.which === 38) {
          _this.onUpPress();
        } else {
          _this.onCustomPress(e.which);
        }
      });
    },

    // keypress actions

    onEnterPress: function onEnterPress() {},
    onEscPress: function onEscPress() {},
    onDownPress: function onDownPress() {},
    onUpPress: function onUpPress() {},
    onCustomPress: function onCustomPress() {}
  });
});