define('ember-select-guru/components/option-component', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;

  exports['default'] = Component.extend({
    classNames: 'ember-select-guru__option',
    classNameBindings: ['current:is-active'],
    current: computed('currentHighlight', 'index', function () {
      return this.get('currentHighlight') === this.get('index');
    }),
    click: function click() {
      this.sendAction("onClick", this.get('option'));
    }
  });
});